<template>
  <div class="page-register">
    <div class="login-bg">
      <img src="../../../assets/login/login_bg_new1.png" alt="" />
    </div>
    <div class="body-contianer">
      <div class="body-wrap">
        <div class="title">{{ $t('login.registerTitle') }}</div>

        <div class="err-msg" v-if="errMsg">{{ errMsg }}</div>

        <div class="ipt-wrap">
          <div class="ipt-group">
            <label>{{ $t('login.mobile') }}</label>
            <el-input
              :placeholder="$t('login.mobileTip')"
              v-model="phoneNum"
              class="input"
            >
              <el-select
                v-model="area"
                slot="prepend"
                :placeholder="$t('login.selectTip')"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="`+${item.cipher}`"
                  :value="item.id"
                  >{{ item.en }}</el-option
                >
              </el-select>
            </el-input>
          </div>
          <div class="ipt-group">
            <label>{{ $t('login.smsCode') }}</label>
            <div class="send-code">
              <el-input
                :placeholder="$t('login.smsCodeTip')"
                v-model="smsCode"
                class="input"
              >
              </el-input>
              <el-button
                :disabled="smsSendFlag"
                type="primary"
                @click="handleSendCode"
              >
                {{ smsBtnText }}
              </el-button>
            </div>
          </div>
          <div class="ipt-group">
            <label>{{ $t('login.password') }}</label>
            <el-input
              ref="input"
              :type="inputType"
              :placeholder="$t('login.passwordTip')"
              v-model="password"
              class="input"
            >
              <i
                :class="{ active: inputType === 'text' }"
                class="el-icon-view el-input__icon"
                slot="suffix"
                @click="changeType"
              >
              </i>
            </el-input>
          </div>
        </div>

        <div class="login-btn">
          <div class="submit">
            <el-button type="primary" round @click="handleRegister">
              {{ $t('login.registerTitle') }}
            </el-button>
          </div>
          <div class="tip">
            {{ $t('login.agreement') }}
            <a href="https://ptego.com/privacy" target="_blank">{{
              $t('login.privacy')
            }}</a>
            {{ $t('login.and') }}
            <a href="https://ptego.com/terms" target="_blank">{{
              $t('login.terms')
            }}</a>
          </div>
        </div>

        <div class="bottom-wrap">
          <div class="links">
            <a href="https://ptego.com/" target="_blank">{{
              $t('login.aboutMe')
            }}</a>
            <a href="https://ptego.com/terms" target="_blank">{{
              $t('login.privacy')
            }}</a>
            <a href="https://ptego.com/terms" target="_blank">{{
              $t('login.terms')
            }}</a>
          </div>
          <div class="copyright">
            PTEGO Copyright © 2021 All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiCheckAreacode,
  apiSendRegisterCode,
  apiSaveRegister
} from '@/api/api.js'

export default {
  data() {
    return {
      areaList: [], //区域列表

      phoneNum: '',
      password: '',
      area: '',
      smsCode: '',

      smsBtnText: this.$t('login.sendSmsCode'),
      smsSendFlag: false,
      errMsg: '',
      inputType: 'password'
    }
  },
  created() {
    this.getAreaCode()
  },
  methods: {
    changeType() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
      this.$refs.input.focus()
    },
    // 获取区域区号【选择国家区域】
    getAreaCode() {
      apiCheckAreacode().then((res) => {
        this.areaList = res.data
        this.area = (this.areaList[0] || {}).id
      })
    },
    handleSendCode() {
      if (this.timeId) {
        return false
      }

      const areaData = this.areaList.find((m) => m.id == this.area)
      if (!this.phoneNum.trim() || !eval(areaData.match).test(this.phoneNum)) {
        this.errMsg = this.$t('login.mobileError')
        return false
      }

      this.smsSendFlag = true
      apiSendRegisterCode({
        phone: this.phoneNum,
        country_code: areaData.cipher
      }).then(({ code, data, msg }) => {
        if (code !== 200) {
          this.errMsg = msg
          this.smsSendFlag = false
          return false
        }

        this.codeKey = data.key
        this.$layer.msg(this.$t('login.sendSmsSuccess'))

        let second = 60
        this.smsBtnText = `(${second}s)${this.$t('login.reSendSmsCode')}`
        this.timeId = setInterval(() => {
          second -= 1
          this.smsBtnText = `(${second}s)${this.$t('login.reSendSmsCode')}`

          if (second === 0) {
            clearInterval(this.timeId)
            this.timeId = 0
            this.smsSendFlag = false
            this.smsBtnText = this.$t('login.sendSmsCode')
          }
        }, 1000)
      })
    },
    handleRegister() {
      const areaData = this.areaList.find((m) => m.id == this.area)

      if (!this.phoneNum.trim() || !eval(areaData.match).test(this.phoneNum)) {
        this.errMsg = this.$t('login.mobileError')
        return false
      }

      apiSaveRegister({
        phone: this.phoneNum,
        country_code: areaData.cipher,
        sms_code: this.smsCode,
        login_pwd: this.password
      }).then(({ code, data, msg }) => {
        if (code === 200) {
          // $layer即lay-ui，在main.js中已经引入
          this.$layer.msg(
            `<div class='success-icon-big'></div><div>${this.$t(
              'login.registerSuccessTip'
            )}</div>`
          )
          this.$router.push({
            name: 'login'
          })
        } else {
          this.errMsg = msg
        }
      })
    },
    handelNavRegister() {
      this.$router.push({
        name: 'register'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-register {
  display: flex;
  color: #3a3c4d;
  .el-icon-view {
    &.active {
      color: #3b86ff;
    }
  }
  .login-bg {
    > img {
      height: 100vh;
    }
  }

  .body-contianer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -9%;
    .body-wrap {
      width: 380px;
      .title {
        font-size: 18px;
        margin-top: 20px;
        font-weight: bold;
        padding-bottom: 14px;
        padding-top: 100px;
      }
      .err-msg {
        padding: 6px 12px;
        color: #ff606d;
        background-color: #ffcfd3;
        border-radius: 5px;
      }
      .ipt-wrap {
        .ipt-group {
          padding-top: 20px;
          > label {
            padding-bottom: 10px;
            font-size: 14px;
          }
          .input {
            margin-top: 10px;
            ::v-deep .el-select {
              width: 74px;
            }
            ::v-deep .el-input-group__prepend {
              background-color: #fff;
            }
          }
          .send-code {
            display: flex;
            ::v-deep .el-button {
              height: 40px;
              min-width: 120px;
              background-color: #3b86ff;
              border-color: #3b86ff;
              margin-top: 10px;
              margin-left: 26px;
              &.is-disabled {
                color: #ffffff;
                background-color: #bcbccb;
                border-color: #bcbccb;
              }
            }
          }
        }
      }
      .login-btn {
        margin-top: 60px;
        .submit {
          ::v-deep .el-button {
            width: 100%;
            background-color: #3b86ff;
            border-color: #3b86ff;
            &.is-disabled {
              color: #fff;
              cursor: not-allowed;
              background-image: none;
              background-color: #a0cfff;
              border-color: #a0cfff;
            }
          }
        }
        .tip {
          font-size: 14px;
          margin-top: 10px;
          color: #7c8084;
          > a {
            color: #3b86ff;
            text-decoration: none;
            &:active,
            &:focus {
              text-decoration: none;
            }
          }
        }
      }
      .bottom-wrap {
        margin-top: 200px;
        text-align: center;
        .links {
          > a {
            width: 33%;
            display: inline-block;
            font-size: 14px;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.45);
            &:after,
            &:focus {
              outline: none;
            }
          }
        }
        .copyright {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>
